<template>
	<settings-container window="95%">
		<h2 class="settings-headline">Kontaktdaten</h2>
		<form
			class="settings-form"
			v-if="accountDetails !== null"
			@submit.prevent="updateAccountDetails"
		>
			<label for="organization-name">{{
				$store.getters.appMode === 'lab' ? 'Laborname' : 'Praxisname'
			}}</label
			><input
				type="text"
				name="organization-name"
				v-model="updatedDetails.name"
				:data-name="
					$store.getters.appMode === 'lab' ? 'Laborname' : 'Praxisname'
				"
				:placeholder="accountDetails.name"
				id="organization-name"
			/>
			<label for="contact-person" v-if="!small">Ansprechpartner</label
			><input
				type="text"
				name="contact-person"
				v-model="updatedDetails.admin_name"
				data-name="Ansprechpartner"
				:placeholder="accountDetails.account.admin_name"
				id="contact-person"
				v-if="!small"
			/>
			<label for="contact-email" v-if="!small">E-Mail Adresse</label
			><input
				type="email"
				name="contact-email"
				v-model="updatedDetails.admin_email"
				data-name="E-Mail Adresse"
				:placeholder="accountDetails.account.admin_email"
				id="contact-email"
				v-if="!small"
			/>
			<label for="contact-telephone" v-if="!small">Telefonnummer</label
			><input
				type="text"
				name="contact-telephone"
				v-model="updatedDetails.admin_tel"
				data-name="Telefonnummer"
				:placeholder="accountDetails.account.admin_tel"
				id="contact-telephone"
				v-if="!small"
			/>
			<button type="submit" class="w-button button">Speichern</button>
			<p class="is-success" v-if="updated && !error">
				Kontaktdaten erfolgreich geändert
			</p>
			<p class="has-error" v-if="updated && error">
				Kontaktdaten konnten nicht geändert werden. Bitte versuchen Sie es
				erneut!
			</p>
		</form>
	</settings-container>
</template>

<script>
export default {
	props: ['small'],
	components: {
		settingsContainer: () =>
			import('@/components/layout/settings-container.vue')
	},
	data() {
		return {
			accountDetails: null,
			updatedDetails: {
				name: '',
				admin_name: '',
				admin_email: '',
				admin_tel: ''
			},
			error: false,
			changed: false,
			updated: false
		};
	},
	watch: {
		updatedDetails: {
			deep: true,
			handler() {
				this.changed = true;
			}
		}
	},
	mounted() {
		this.getAccountDetails();
	},
	methods: {
		async getAccountDetails() {
			const accountResponse = await this.$api.post(
				'/account/get_account_details',
				{
					account_id: this.$store.getters.getAccount.account_id,
					small: this.small
				},
				{
					headers: {
						Authorization: `Bearer ${this.$store.getters.getUserToken}`
					}
				}
			);
			this.accountDetails = accountResponse.data;
		},
		async updateAccountDetails() {
			if (this.changed) {
				const accountUpdateResponse = await this.$api.post(
					'/account/update_account_details',
					{
						account_id: this.small
							? null
							: this.$store.getters.getAccount.account_id,
						details: this.updatedDetails,
						small: this.small
					},
					{
						headers: {
							Authorization: `Bearer ${this.$store.getters.getUserToken}`
						}
					}
				);
				if (accountUpdateResponse.status === 200) {
					this.error = false;
					this.updated = true;
					await this.getAccountDetails();
					this.changed = false;
				} else {
					this.error = true;
					await this.getAccountDetails();
					this.changed = false;
					this.updated = true;
				}
			}
		}
	}
};
</script>

<style></style>
